import { Component } from 'vue-property-decorator';
import { ConfigurationProps, DataWorkflowsType, DocumentationTab } from '@/types';
import DocMixin from '@/mixins/data-operations/doc/doc-mixin';
import { CONFIGURATIONS } from '@/constants/data-operations/status';
import { Base64 } from 'js-base64';

@Component
export default class ConfigurationDocMixin extends DocMixin {
	get type(): DataWorkflowsType {
		return CONFIGURATIONS;
	}

	get updateInformation() {
		// TODO: Add User info
		return {
			update_date: this.item.update_date || this.item.updated_date,
			updated_by: this.item.updated_by,
		};
	}

	get configurationProps(): ConfigurationProps {
		return {
			archivedConfsModuleName: this.archivedConfsModuleName,
			docId: this.itemId,
			isLoading: this.isLoading,
			isNotFound: this.isNotFound,
			moduleName: this.moduleName,
			tabsItems: this.itemTabsItems,
			type: this.type!,
			updateInformation: this.updateInformation,
		};
	}

	get documentationTab(): DocumentationTab {
		return {
			label: 'Documentation',
			href: 'documentation',
			component: {
				name: 'documentation-tab',
				props: {
					content: this.item.doc_md ? Base64.decode(this.item.doc_md) : '',
				},
			},
		};
	}

	get itemTabsItems(): any {
		if (Object.keys(this.item).length === 0) return [];
		return [this.configurationTab, this.documentationTab, this.fullJSONTab, this.notesTab];
	}
}
